/* eslint-disable no-unused-expressions */
require('./src/styles/reset.css');
require('./src/styles/global.sass');
require('./src/styles/utilities.sass');

exports.onClientEntry = () => {
  if (!!window.MSInputMethodContext && !!document.documentMode) {
    import('./src/styles/ie-overrides.css');
  }

  // IntersectionObserver polyfill for gatsby-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`);
  }
};
